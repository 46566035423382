import React, { useState } from "react";
import styled from "styled-components";
import { toast } from "react-toastify";
import { useNavigate, useSearchParams } from "react-router-dom";
import Cookies from "js-cookie";
import bg from "../../assets/images/kids-bg-2.jpg";
import Wififlow from "../Subscription/Wififlow";
import Heflow from "../Subscription/Heflow";
import { handleMessage, postRequest } from "../../lib/lib";
import Loader from "../Loader";

const initState = {
  message: "",
  hide_browse: false,
  sub: false,
};
const Login = () => {
  const [loader, setLoader] = useState(false);
  const [flow, setFlow] = useState("WIFI");
  const [pack, setPack] = useState("Daily");
  const [uiState, setUistate] = useState(initState);
  const [number, setNumber] = useState("");
  const [q] = useSearchParams();
  const nav = useNavigate();

  const optIn = async (eve) => {
    eve.preventDefault();
    const { name } = eve.target;
    console.log("BUTTON NAME ->", name);

    if (number.length <= 0 || number.length < 5) {
      return toast.info("Number required");
    }

    let msisdn = "";
    if (
      number.charAt(0) === "2" &&
      number.charAt(1) === "3" &&
      number.charAt(2) === "2"
    ) {
      msisdn = number;
    } else if (number.charAt(0) === "0") {
      msisdn = number.replace("0", "232");
    } else {
      msisdn = "232" + number;
    }

    let SUB_URL = "sub-user";
    let CHECK_SUB = `check-subscription`;

    let API_URL;

    if (name === "login") {
      API_URL = CHECK_SUB;
    } else {
      API_URL = SUB_URL;
    }

    const body = {
      pack_type: pack,
      msisdn,
      m_act: "WEB",
      language: "en",
      ext_ref: q.get("ext_ref"),
    };
    // console.log(body);
    setLoader(true);
    const [e1, data] = await postRequest(API_URL, body);
    setLoader(false);
    console.log({ e1, data, API_URL });

    if (e1?.code === "ERR_NETWORK") {
      return toast.error(e1.message);
    }
    if (e1?.response.status === 403 || e1?.response.status === 500)
      return toast.error("Unable to process your request");

    if (data?.result === 1 || e1?.response?.status === 400) {
      const server_cookie = data?.cookie || e1.response.data.cookie;
      console.log("VALID USER ->", msisdn);
      Cookies.set("sl-orange-msisdn", msisdn, {
        expires: server_cookie,
      });
      setUistate({ message: handleMessage("exist"), sub: true });
      setTimeout(() => {
        return nav("/redirect");
      }, 5000);
    } else if (data.result === 2) {
      // new Sub 2 for new sub
      // only for sub api
      console.log("NEW->SUB->", msisdn);
      Cookies.set("sl-orange-msisdn", msisdn, {
        expires: data.cookie,
      });
      setUistate({ message: handleMessage("sub", pack), sub: true });
      setTimeout(() => {
        return nav("/redirect");
      }, 5000);
    } else if (data.result === 3) {
      console.log("NOT VALID USER ->", msisdn);
      setUistate({ hide_browser: true, message: handleMessage("noSub") });
      return toast.info(data.msg);
      // for not valid user
      // only for check ub api
    } else if (data.result === 0) {
      // for server erorrs
      // for all api
      return toast.info(data.msg);
    } else {
      toast.info(data.msg);
      return nav("/");
    }
  };

  return (
    <React.Fragment>
      <HomeContainer>
        <ContentContainer>
          <InfoBox>
            {/* <h1 style={{ fontSize: "16px" }}>
            WELCOME TO KIDZ MANIA WATCH UNLIMITED VIDEOS
          </h1> */}
            {flow === "WIFI" ? (
              <Wififlow
                setNumber={setNumber}
                setPack={setPack}
                optIn={optIn}
                pack={pack}
                loader={loader}
                uiState={uiState}
              />
            ) : flow === "HE" ? (
              <Heflow
                setNumber={setNumber}
                setPack={setPack}
                optIn={optIn}
                pack={pack}
                loader={loader}
                uiState={uiState}
              />
            ) : (
              ""
            )}
          </InfoBox>
        </ContentContainer>
      </HomeContainer>
      {loader && <Loader />}
    </React.Fragment>
  );
};

export default Login;

const HomeContainer = styled.div`
  background-image: url(${bg});
  /* linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.4)); */
  /* background-color: #fff; */
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  background-blend-mode: overlay;
  box-sizing: border-box;
  padding: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: 768px) {
    padding: 25px 0 0;
  }
`;

const ContentContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  margin-top: 10px;
  width: 70%;
  @media screen and (max-width: 768px) {
    width: 100%;
    flex-direction: column-reverse;
  }
`;
const InfoBox = styled.div`
  min-width: 60%;
  max-width: 100%;
  width: 500px;
  margin-top: -60px;
  h1 {
    color: black;
    /* font-size: 25px; */
    font-weight: 900;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI",
      Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
      sans-serif;
    text-align: center;
    font-size: 14px;
    padding: 9px;
  }
`;
