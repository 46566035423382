import React, { useEffect, useState } from "react";
import { Routes, Route, useNavigate, useSearchParams } from "react-router-dom";
import { CookiesProvider } from "react-cookie";

import Layout from "../Layout/Layout";
import styled from "styled-components";
import Spinner from "../Spinner/Spinner";
import ErrorPage from "../../containers/ErrorPage/ErrorPage";
import Login from "../Login/Login";
import VideoDescription from "../../containers/VideoDescription/VideoDescription";
import Cookies from "js-cookie";
import { postRequest } from "../../lib/lib";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Welcome from "../Welcome";

const DOIRedirect = React.lazy(() =>
  import("../../containers/DOIRedirect/DOIRedirect")
); // Lazy-loaded

const Navigations = () => {
  const nav = useNavigate();
  const [q] = useSearchParams();

  useEffect(() => {
    console.log(window.location.host)
    if(window.location.host === "kiddzmania.com") {
      return nav("/welcome")
    } else {
      const msisdn = Cookies.get("sl-orange-msisdn");

      if (msisdn && msisdn !== "null") {
        return nav("/redirect");
      } else if (q.get("ani")) {
        console.log("USSD_REF->");
        ussdSubCheck();
      } else {
        let ext_ref = q.get("ext_ref");
        let l = ext_ref && ext_ref != 'null' ?  `/?ext_ref=${ext_ref}` : "/";
        return nav(l);
      }
    }
    
  }, []);

  const ussdSubCheck = async () => {
    const query = { msisdn: q.get("ani") };
    if (!query) return nav("/");
    const [e1, data] = await postRequest("check-subscription", query);

    if (e1) {
      if (e1.response.status === 403)
        return toast.error("Unable to process your request");
      else return toast.error("Netwrok error");
    }
    if (data.result === 1) {
      Cookies.set("sl-orange-msisdn", q.get("ani"), {
        expires: data.cookie,
      });
      return nav("/redirect");
    } else {
      toast.info(data.msg);
      return nav("/");
    }
  };
  return (
    <React.Suspense
      fallback={
        // <h1>Hwllo</h1>
        <SuspenseStyled>
          <Spinner />
        </SuspenseStyled>
      }
    >
      <ToastContainer theme="light" position="top-right" autoClose={3000} />
      <CookiesProvider>
        <Layout>
          <Routes>
            <Route path="/welcome" element={ <Welcome />} />
            <Route path="/" element={<Login />} />
            {/* <Route path="/flow" element={<Flow />} /> */}
            {/* <Route path="/login" element={<Login />} /> */}
            {/* <Route path="/subscribe" element={<Subscription />} />        */}
            <Route path="/redirect" element={<DOIRedirect />} />
            <Route path="/video/:id" element={<VideoDescription />} />
            <Route path="*" element={<ErrorPage />} />
          </Routes>
        </Layout>
      </CookiesProvider>
    </React.Suspense>
  );
};

export default Navigations;

const SuspenseStyled = styled.div`
  background-image: linear-gradient(to right, #141e30, #243b55);
  /* background-color: yellow; */
  position: fixed;
  top: 0;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
